<template>
  <Layout>
    <template v-slot:prepend>
      ({{ rowType }})
    </template>
    <template v-slot:label>
      {{ row.label }}
    </template>
    <template v-slot:extra>
      {{ row.extra }}
    </template>
    <template v-slot:meta>
      {{ keypath }}
    </template>
  </Layout>
</template>
<script>
const Layout = () => import('./Layout')

export default {
  name: 'UnknownInput',

  components: {
    Layout
  },

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.value
    }
  },

  computed: {
    rowType () {
      if (this.row.type) return this.row.type
      return 'Missing'
    }
  }
}

</script>
